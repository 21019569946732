import NavLink from 'components/NavLink';
import styled from '@emotion/styled';

type Props = {
  horizontal?: boolean;
};

const RailLink = styled(NavLink)<Props>(({ horizontal = false }) => ({
  marginBottom: '1.9rem',
  marginRight: 0,
  marginTop: 0,

  ...(horizontal
    ? {
        marginBottom: 0,
        marginRight: '2.4rem',
        marginTop: '1.2rem',
      }
    : {}),

  '&:last-child': {
    marginBottom: 0,
  },
}));

export default RailLink;
